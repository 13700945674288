import React, { useState, useEffect } from 'react';
import { IoGameController, IoCloseCircle } from 'react-icons/io5';
import './Games.css';
import axios from 'axios';
import { API_URL } from '../../const';

const Games = ({visheshamData}) => {
  const [showChat, setShowChat] = useState(false);
  const [name1, setName1] = useState('');
  const [name2, setName2] = useState('');
  const [result, setResult] = useState('');
  const [quote, setQuote] = useState('');
  const [loading, setLoading] = useState(false);
  const [user1, setUser1] = useState("");
  const [user2, setUser2] = useState("");

  useEffect(() => {
    if (visheshamData && visheshamData.guest_of_honor) {
        if (visheshamData.guest_of_honor.length > 0) {
            setUser1(visheshamData.guest_of_honor[0].name);
        }
        if (visheshamData.guest_of_honor.length > 1) {
            setUser2(visheshamData.guest_of_honor[1].name);
        }
    }
    window.scroll(0, 0);
  }, [visheshamData]);

  const handleCalculateFLAMES = () => {
    if(name1.trim() === "" || name2.trim() === ""){
        return;
    }
    const flames = (n1, n2) => {
      const commonChars = (s1, s2) => {
        let freq = {};
        for (let char of s1) freq[char] = (freq[char] || 0) + 1;
        for (let char of s2) freq[char] = (freq[char] || 0) - 1;
        return Object.values(freq).reduce((sum, count) => sum + Math.abs(count), 0);
      };

      let count = commonChars(n1.toLowerCase().replace(/\s+/g, ''), n2.toLowerCase().replace(/\s+/g, ''));
      const flamesList = ['F', 'L', 'A', 'M', 'E', 'S'];
      let index = 0;

      while (flamesList.length > 1) {
        index = (index + count - 1) % flamesList.length;
        flamesList.splice(index, 1);
      }

      return flamesList[0];
    };

    setLoading(true);
    setTimeout(() => {
      const result = flames(name1, name2);
      const resultMap = {
        'F': 'Friends',
        'L': 'Love',
        'A': 'Affection',
        'M': 'Marriage',
        'E': 'Enemy',
        'S': 'Siblings',
      };
      var tempResult = resultMap[result];
      setResult(tempResult);
      var randomQuote = quotes[result][Math.floor(Math.random() * quotes[result].length)];
      setQuote(randomQuote);
      setLoading(false);
      
      if((name1.replace(/\s/g, '').trim().toLowerCase() === user1.replace(/\s/g, '').trim().toLowerCase() || name2.replace(/\s/g, '').trim().toLowerCase() === user1.replace(/\s/g, '').trim().toLowerCase()) 
        && (name1.replace(/\s/g, '').trim().toLowerCase() === user2.replace(/\s/g, '').trim().toLowerCase() || name2.replace(/\s/g, '').trim().toLowerCase() === user2.replace(/\s/g, '').trim().toLowerCase())
        && (name1.replace(/\s/g, '').trim().toLowerCase() !== name2.replace(/\s/g, '').trim().toLowerCase()) && result !== 'M'){
          tempResult = tempResult +"-"+resultMap['M'];
          setResult(tempResult);
          randomQuote = quotes['M'][Math.floor(Math.random() * quotes['M'].length)];
          setQuote(randomQuote);
      }
      axios.post(API_URL+"insertFlamesResult",
      {
        vishesham_id: visheshamData.id,
        your_name: name1,
        crush_name: name2,
        quote_showed: randomQuote,
        result: tempResult
      }).then(() => {}).catch((e) => {console.log(e);});
    }, 500);

    
  };

  const handleClose = () => {
    setShowChat(false);
    setName1('');
    setName2('');
    setResult('');
    setQuote('');
  };

  return (
    <div>
      <div
        className="icon"
        onClick={() => setShowChat(!showChat)}
      >
        {showChat ? <IoCloseCircle onClick={handleClose}/> : <IoGameController />}
      </div>

      {showChat && (
        <div className={`chatbot-container ${!showChat ? 'hidden' : ''}`}>
          <div className="chatbot-header">
            FLAMES Calculator
            <IoCloseCircle
              className="close-icon"
              onClick={handleClose}
            />
          </div>
          <input
            type="text"
            className="chatbot-input"
            placeholder="Your Name"
            value={name1}
            onChange={(e) => setName1(e.target.value)}
          />
          <input
            type="text"
            className="chatbot-input"
            placeholder="Crush's Name"
            value={name2}
            onChange={(e) => setName2(e.target.value)}
          />
          <button
            className="chatbot-button"
            onClick={handleCalculateFLAMES}
            disabled={loading}
          >
            {loading?"Calculating...":"Calculate"}
          </button>
          {loading && <div className="spinner"></div>}
          {!loading && result && (
            <div className="chatbot-result">
              <p>
                Result: {result.includes('-') ? (
                  <>
                    <span className="strikethrough">
                      {result.split('-')[0]}
                    </span>
                    <span>
                      {" "+result.split('-')[1]}
                    </span>
                  </>
                ) : (
                  result
                )}
              </p>
              <p className="chatbot-quote">{quote}</p>
            </div>
          )}

        </div>
      )}
    </div>
  );
};

const quotes = {
    F: [
      "Friends are the siblings God never gave us.",
      "Friendship is born at that moment when one person says to another, 'What! You too? I thought I was the only one.'",
      "A real friend is one who walks in when the rest of the world walks out.",
      "Friends are those rare people who ask how we are and then wait to hear the answer.",
      "Friendship is not about whom you have known the longest... It’s about who came and never left your side.",
      "True friendship is never serene.",
      "Friendship is the only cement that will ever hold the world together.",
      "True friendship is a plant of slow growth.",
      "Friendship is a sheltering tree.",
      "Friends are like stars. You don’t always see them, but you know they’re always there.",
      "A friend is someone who knows all about you and still loves you.",
      "There are no strangers here; only friends you haven’t yet met.",
      "A friend is a treasure, and the more friends you have, the richer you are.",
      "Friendship is a lifelong bond that is cherished.",
      "Friendship is when you can be yourself and not be judged.",
      "A friend is someone who makes you laugh even when you think you’ll never smile again.",
      "Friendship is a mutual bond of affection.",
      "Friends are the family we choose for ourselves.",
      "A friend is someone who understands your past, believes in your future, and accepts you just the way you are."
    ],
    L: [
      "Love is not about how much you say 'I love you,' but how much you prove that it's true.",
      "The best thing to hold onto in life is each other.",
      "Love looks not with the eyes, but with the mind, and therefore is winged Cupid painted blind.",
      "To love and be loved is to feel the sun from both sides.",
      "Love is composed of a single soul inhabiting two bodies.",
      "In vain have I struggled. It will not do. My feelings will not be repressed. You must allow me to tell you how ardently I admire and love you.",
      "Love is when the other person's happiness is more important than your own.",
      "You don’t love someone because they’re perfect; you love them despite the fact that they’re not.",
      "Love is the only reality, and it is not a mere sentiment. It is the ultimate truth that lies at the heart of creation.",
      "I have waited for this opportunity for more than half a century, to repeat to you once again my vow of eternal fidelity and everlasting love.",
      "Love is not something you find. Love is something that finds you.",
      "To be your friend was all I ever wanted; to be your lover was all I ever dreamed.",
      "Where there is love there is life.",
      "Love is an endless act of forgiveness. Forgiveness is the key to action and freedom.",
      "Love is the master key that opens the gates of happiness.",
      "You call it madness, but I call it love.",
      "The greatest happiness of life is the conviction that we are loved; loved for ourselves, or rather, loved in spite of ourselves.",
      "Love is a friendship set to music.",
      "To love and be loved is to feel the sun from both sides."
    ],
    A: [
      "Affection is when you see someone and you can’t stop smiling.",
      "Affection is a feeling of love and warmth that makes you feel special and appreciated.",
      "Affection is a gentle feeling of fondness and love.",
      "Affection is the glue that holds relationships together.",
      "Affection is a way of showing someone you care and value them.",
      "Affection is like a warm hug for the soul.",
      "Affection is when you feel happy just being around someone.",
      "Affection is the subtle way of expressing love and care.",
      "Affection is the kindness we show to those we love.",
      "Affection is the act of showing warmth and tenderness.",
      "Affection is a sincere and genuine display of love.",
      "Affection is when you miss someone even before they leave.",
      "Affection is the small gestures that make a big difference.",
      "Affection is the emotional connection we share with loved ones.",
      "Affection is the love that is expressed through little things.",
      "Affection is the heartbeat of any loving relationship.",
      "Affection is the feeling of being cared for and cherished.",
      "Affection is the gentle touch that communicates love.",
      "Affection is when you care enough to make someone smile.",
      "Affection is the warmth in a hug or a kind word."
    ],
    M: [
      "Marriage is not just a spiritual union, it’s a partnership.",
      "A successful marriage requires falling in love many times, always with the same person.",
      "Marriage is a journey of discovery with someone you love.",
      "The best thing to hold onto in life is each other.",
      "Marriage is the alliance of two people, one of whom never remembers birthdays and the other who never forgets.",
      "A happy marriage is the union of two good forgivers.",
      "Marriage is a bond that lasts a lifetime, built on love and trust.",
      "Marriage is like a deck of cards. At the start, all you need is two hearts and a diamond. By the end, you wish you had a club and a spade.",
      "Marriage is the only war where you sleep with the enemy.",
      "Marriage is an art of compromise and understanding.",
      "Marriage is a partnership where each person completes the other.",
      "In marriage, it’s not about finding the right person, but being the right person.",
      "Marriage is a promise to weather the storms of life together.",
      "A successful marriage is one where both partners feel like they are winning.",
      "Marriage is a lifelong friendship with your best friend.",
      "Marriage is not just about living together, it’s about growing together.",
      "A happy marriage is a long conversation which always seems too short.",
      "Marriage is the foundation of a happy family.",
      "Marriage is a beautiful journey, filled with ups and downs, but always worth it."
    ],
    E: [
      "An enemy is just a friend you haven’t made yet.",
      "The best way to destroy an enemy is to make him a friend.",
      "An enemy is someone who is waiting for you to fail.",
      "An enemy is just a person who needs love and understanding.",
      "Sometimes the best way to deal with an enemy is to turn them into a friend.",
      "The best revenge is to be unlike him who performed the injury.",
      "An enemy is simply someone who is misunderstood.",
      "If you want to make peace, you don’t talk to your friends. You talk to your enemies.",
      "Turning an enemy into a friend is a sign of strength.",
      "The greatest victory is to turn an enemy into a friend.",
      "An enemy is a friend you haven’t met yet.",
      "The more you know about your enemy, the more you will love them.",
      "An enemy is just someone who hasn’t yet found their place in your life.",
      "The only way to deal with an enemy is with kindness and understanding.",
      "An enemy is a person who is hurting and needs help.",
      "The best way to get rid of an enemy is to convert them into a friend.",
      "Enemies are just friends in disguise.",
      "Turning an enemy into a friend is the highest form of victory.",
      "An enemy is just someone who hasn’t yet found their place in your life."
    ],
    S: [
      "Siblings are like branches on a tree, we grow in different directions yet our roots remain as one.",
      "Siblings are the people we practice on, the people who teach us about fairness and cooperation.",
      "Siblings: your only enemies you can’t live without.",
      "A sibling is both your mirror and your opposite.",
      "Having a sibling means having a lifelong friend.",
      "Siblings may drive you crazy, get into your stuff, and irritate you. However, if anyone else dares say so much as a word about it, a sibling will defend you to the death.",
      "Siblings are a big part of who you are and where you come from.",
      "A sibling is someone who knows you inside out, and still loves you.",
      "Siblings are the family you can choose and the friends who can’t leave.",
      "The bond between siblings is unbreakable and forever.",
      "Siblings are like stars; you may not always see them, but you know they’re always there.",
      "A sibling is a friend for life, even when they drive you crazy.",
      "Siblings are your partners in crime and your biggest supporters.",
      "Having a sibling means having a friend who understands your past, believes in your future, and accepts you just the way you are.",
      "Siblings may be different in every way, but they share the same heart.",
      "A sibling is a built-in friend for life.",
      "Siblings are the people who know you the best and love you the most.",
      "A sibling is someone who shares your childhood memories and childhood dreams.",
      "Siblings are the family that we learn to love unconditionally."
    ]
  };

export default Games;
