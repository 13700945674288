import React, { useState, useEffect } from "react";
import '../../App.css';
import { FaRegHeart, FaRegPlusSquare } from "react-icons/fa";
import { API_URL } from '../../const';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Gallery = ({ visheshamData }) => {
    const [imageSrcs, setImageSrcs] = useState([]);

    useEffect(() => {
        if (visheshamData && visheshamData.gallery_images) {
            const initialImageSrcs = visheshamData.gallery_images.map(item => 
                item.image_url ? API_URL + item.image_url : '../../Img/default_profile.gif'
            );
            setImageSrcs(initialImageSrcs);
        }
    }, [visheshamData, '../../Img/default_profile.gif']);

    const handleImageError = (index) => {
        setImageSrcs(prevImageSrcs => {
            const newImageSrcs = [...prevImageSrcs];
            newImageSrcs[index] = '../../Img/default_profile.gif';
            return newImageSrcs;
        });
    };

    const handleImageLoad = (index) => {
        setImageSrcs(prevImageSrcs => {
            const newImageSrcs = [...prevImageSrcs];
            newImageSrcs[index] = API_URL + visheshamData.gallery_images[index].image_url;
            return newImageSrcs;
        });
    };

    const options = {
        autoplay: false,
        smartSpeed: 1500,
        dots: false,
        loop: true,
        nav: true,
        navText: [
            '<div style="width: 60px; height: 60px; display: flex; align-items: center; justify-content: center; color: #E47A2E; background: #FFFFFF; font-size: 30px;"><i class="fa fa-angle-left" aria-hidden="true"></i><div>',
            '<div style="width: 60px; height: 60px; display: flex; align-items: center; justify-content: center; color: #E47A2E; background: #FFFFFF; font-size: 30px;"><i class="fa fa-angle-right" aria-hidden="true"></i><div>'
        ],
        responsive: {
            0: { items: 1 },
            576: { items: 2 },
            768: { items: 3 },
            992: { items: 4 },
            1200: { items: 5 }
        }
    };

    const galleryStyle = {
        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${visheshamData ? API_URL + visheshamData.gallery_thumbnail : '../../Img/gallery.jpg'}) no-repeat center center`,
        backgroundSize: 'cover',
        padding: '120px 0',
        margin: '90px 0',
    };

    return (
        <div className="container-fluid" id="gallery" style={galleryStyle}>
            <div className="section-title position-relative text-center" style={{ marginBottom: "120px" }}>
                <h6 className="text-uppercase text-primary mb-3" style={{ letterSpacing: "3px" }}>Gallery</h6>
                <h1 className="font-secondary display-4 text-white">Our Photo Gallery</h1>
                <FaRegHeart />
            </div>
            <div className="owl-carousel gallery-carousel" style={{ display: "flex" }}>
                <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
                    {
                        visheshamData && visheshamData.gallery_images ?
                            visheshamData.gallery_images.map((item, index) => (
                                <div key={index} className="gallery-item">
                                    <img
                                        className="img-fluid w-100"
                                        src={imageSrcs[index]}
                                        alt=""
                                        onLoad={() => handleImageLoad(index)}
                                        onError={() => handleImageError(index)}
                                    />
                                    <a href={API_URL + item.image_url} data-lightbox="gallery">
                                        <FaRegPlusSquare />
                                    </a>
                                </div>
                            ))
                            : <p className="text-white">No images available</p>
                    }
                </OwlCarousel>
            </div>
        </div>
    );
};

export default Gallery;

