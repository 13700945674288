import React, { useState, useEffect } from "react";
import { API_URL } from '../../const';
import { Carousel } from 'react-bootstrap';

const CustomCarousel = ({visheshamData}) => {
  const [videoUrl, setVideoUrl] = useState(""); // State for video URL
  const [user1, setUser1] = useState("");
  const [user2, setUser2] = useState("");
  
  useEffect(() => {
    if (visheshamData && visheshamData.guest_of_honor) {
        if (visheshamData.guest_of_honor.length > 0) {
            setUser1(visheshamData.guest_of_honor[0].name);
        }
        if (visheshamData.guest_of_honor.length > 1) {
            setUser2(visheshamData.guest_of_honor[1].name);
        }
    }
    window.scroll(0, 0);
  }, [visheshamData]);

  const handleVideoButtonClick = (url) => {
    const videoID = url.split('v=')[1].split('&')[0];
    const embedUrl = `https://www.youtube.com/embed/${videoID}`;
    setVideoUrl(embedUrl);
  };

  return (
    <>
      <div className="container-fluid p-0 mb-5 pb-5" id="home">
        <Carousel fade>
          {visheshamData ? (
            <Carousel.Item style={{ height: "100vh", minHeight: "400px" }}>
              <img
                className="d-block w-100"
                src={API_URL + visheshamData.thumbnail}
                style={{ objectFit: "cover", height: "100%",
                  WebkitFilter: "brightness(50%)",
                  filter: "brightness(40%) saturation(100%)",
                }}
                alt="slide"
              />
              <Carousel.Caption style={{ backgroundColor: "transparent" }}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="p-3" style={{ maxWidth: "900px" }}>
                    <h1 className="display-1 shad font-secondary text-white mt-n3 mb-md-4">{user1} & <br/>{user2}</h1>
                    <div className="d-inline-block border-top border-bottom border-light py-3 px-4">
                      <h3 className="shade text-uppercase font-weight-normal text-white m-0" style={{ letterSpacing: "2px", textShadow: "2px 2px 1.5px black", fontWeight:"bold" }}>We're getting married</h3>
                    </div>
                    {/* <button
                      type="button"
                      className="btn-play mx-auto"
                      onClick={() => handleVideoButtonClick("https://www.youtube.com/watch?v=EqNzINKsrhE")}
                      data-bs-toggle="modal"
                      data-bs-target="#videoModal"
                    >
                      <span></span>
                    </button> */}
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ) : (
            <Carousel.Item style={{ height: "100vh", minHeight: "400px" }}>
              <img
                className="d-block w-100"
                src="img/carousel-1.jpg"
                style={{ objectFit: "cover", height: "100%",
                  WebkitFilter: "brightness(50%)",
                  filter: "brightness(50%)",
                  }}
                alt="slide"
              />
              <Carousel.Caption>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="p-3" style={{ maxWidth: "900px" }}>
                    <h1 className="display-1 font-secondary text-white mt-n3 mb-md-4">Bride & Groom</h1>
                    <div className="d-inline-block border-top border-bottom border-light py-3 px-4">
                      <h3 className="text-uppercase font-weight-normal text-white m-0" style={{ letterSpacing: "2px" }}>We're getting married</h3>
                    </div>
                    {/* <button
                      type="button"
                      className="btn-play mx-auto"
                      onClick={() => handleVideoButtonClick("https://www.youtube.com/watch?v=mZ7aJlPASng")}
                      data-bs-toggle="modal"
                      data-bs-target="#videoModal"
                    >
                      <span></span>
                    </button> */}
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          )}
        </Carousel>
      </div>

      <div className="modal fade" id="videoModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" id="video" src={videoUrl} allowscriptaccess="always" allow="autoplay"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomCarousel;
