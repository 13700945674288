import React from "react";
import { FaRegHeart } from "react-icons/fa";
import {API_URL} from '../../const';

const Story = ({visheshamData}) => {

    const formatDate = (dateString) => { 
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        return date.toLocaleString('en-US', options);
    };
    
  return (
    visheshamData && visheshamData.events ?
    <div className="container-fluid py-5" id="story">
        <div className="container pt-5 pb-3">
            <div className="section-title position-relative text-center">
                <h6 className="text-uppercase text-primary mb-3" style={{letterSpacing: "3px"}}>Story</h6>
                <h1 className="font-secondary display-4">Events</h1>
                <FaRegHeart />
            </div>
            <div className="container timeline position-relative p-0">
            
            { 
                visheshamData.events.map((item, index) =>{
                    return(
                        <>
                        {
                            index % 2 == 0?
                            <div className="row">
                                <div className="col-md-6 text-center text-md-right">
                                    <img className="img-fluid mr-md-3" src={API_URL+item.thumbnail} alt=""/>
                                </div>
                                <div className="col-md-6 text-center text-md-left">
                                    <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                                        <h4 className="mb-2">{item.event_title}</h4>
                                        <p className="text-uppercase mb-2">{formatDate(item.event_date)}</p>
                                        <p className="m-0">{item.event_description}</p>
                                    </div>
                                </div>
                            </div>:
                            <div className="row">
                                <div className="col-md-6 text-center text-md-right">
                                    <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 mr-md-3">
                                        <h4 className="mb-2">{item.event_title}</h4>
                                        <p className="text-uppercase mb-2">{formatDate(item.event_date)}</p>
                                        <p className="m-0">{item.event_description}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 text-center text-md-left">
                                    <img className="img-fluid ml-md-3" src={API_URL+item.thumbnail} alt=""/>
                                </div>
                            </div>
                        }
                        </>
                        
                    )
                })
            }
            
            </div>
        </div>
    </div>
    : null
  );
};

export default Story;
