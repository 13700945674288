import React, {useEffect, useState} from 'react';
import './Envelope.css';

const Envelope = ({invitationToName, invitationMessage}) => {

    const [visible, setVisible] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 7000);

        return () => clearTimeout(timer);
    }, []);
  return (
    <>
    {
        visible?
        <div id="envelopwrapper">
        <div class="envelope open">
            <div class="flap front"></div>
            <div class="flap top"></div>
            <div class="letter">
                <div className="letter-content" style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                <h2 style={{fontSize: "24px", fontFamily: "ui-rounded"}}>TO: <span style={{fontSize: "24px", fontFamily: "ui-rounded", fontStyle: "italic"}}>{invitationToName}</span></h2>
                {
                    invitationMessage?<span style={{fontSize: "20px", fontFamily: "ui-rounded", fontStyle: "italic"}}>{invitationMessage}</span>:null
                }
                </div>
            </div>
        </div>
        
        </div>:null
    }
        
    </>
  );
};

export default Envelope;
