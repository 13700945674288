import React from "react";
import './FoodMenu.css'; 

const FoodMenu = ({visheshamData}) => {

  // const dinnerList = ["Chicken Curry", "Mutton Biryani", "Paneer Butter Masala", "Naan", "Gulab Jamun"];
  // const breakfastList = ["Idli", "Dosa", "Pongal", "Vada", "Coffee"];

  // const foodMenu = visheshamData.food_menu;
  // const dinnerList = visheshamData.food_menu.filter(filterDinner)//["Chicken Curry", "Mutton Biryani", "Paneer Butter Masala", "Naan", "Gulab Jamun"];
  // const breakfastList = visheshamData.food_menu.filter(filterBreakFast);//["Idli", "Dosa", "Pongal", "Vada", "Coffee"];
  // function filterDinner(food) {
  //   return food.food_menu_is_dinner == 1;
  // }
  // function filterBreakFast(food){
  //   return food.food_menu_is_dinner == 0;
  // }
  return (
    <>
    {
      visheshamData!== null && visheshamData !== undefined && visheshamData.food_menu !== undefined && visheshamData.food_menu.length > 0?
    <div className="foodMenu-container">
      <div className="foodMenu-menu">
        <h2 className = "vishh2">Menu</h2>
        <div className="foodMenu-meal-container">
          <div className="foodMenu-meal" id="dinner">
            <h3 className = "vishh3"><i className="fas fa-utensils"></i> Dinner</h3>
            <ul id="dinner-list">
              {
              visheshamData.food_menu.filter(prod => prod.food_menu_is_dinner === "1").map((item, index) => {
                return(
                  <li key={index}>
                  {item.food_menu_name}
                </li>
                )
              })
              // dinnerList.map((item, index) => (
                
              // ))
              }
            </ul>
          </div>
          <div className="foodMenu-meal" id="breakfast">
            <h3 className = "vishh3"><i className="fas fa-coffee"></i> Breakfast</h3>
            <ul id="breakfast-list">
            {
                visheshamData.food_menu.filter(prod => prod.food_menu_is_dinner === "0").length > 0?
              visheshamData.food_menu.filter(prod => prod.food_menu_is_dinner === "0").map((item, index) => {
                return(
                  <li key={index}>
                  {item.food_menu_name}
                </li>
                )
              })
              :
              null
              // dinnerList.map((item, index) => (
                
              // ))
            }

              {/* {breakfastList.map((item, index) => (
                <li key={index}>
                  {item}
                </li>
              ))} */}
            </ul>
          </div>
        </div>
      </div>
    </div>
    :
    null
    }
    </>
  );
};

export default FoodMenu;
