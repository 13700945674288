import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";


const NavBar = ({ visible, visheshamData }) => {
    const [user1, setUser1] = useState("");
    const [user2, setUser2] = useState("");
    
    useEffect(() => {
      if (visheshamData && visheshamData.guest_of_honor) {
          if (visheshamData.guest_of_honor.length > 0) {
              setUser1(visheshamData.guest_of_honor[0].name);
          }
          if (visheshamData.guest_of_honor.length > 1) {
              setUser2(visheshamData.guest_of_honor[1].name);
          }
      }
      window.scroll(0, 0);
    }, [visheshamData]);
  return (
    <nav className={`navbar fixed-top shadow-sm navbar-expand-lg bg-dark py-3 py-lg-0 px-lg-5 ${visible ? "d-flex" : "d-none"}`}>
      <a href="/" className="navbar-brand d-block d-lg-none">
        <h1 className="font-secondary shad text-white mb-n2">
          {user1} <span className="text-primary">&</span> {user2}
        </h1>
      </a>
      <button
        type="button"
        className="navbar-toggler"
        data-toggle="collapse"
        data-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
        <div className="navbar-nav ml-auto py-0">
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item nav-link"
          >
            Home
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item nav-link"
          >
            About
          </Link>
          <Link
            activeClass="active"
            to="story"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item nav-link"
          >
            Story
          </Link>
          <Link
            activeClass="active"
            to="gallery"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item nav-link"
          >
            Gallery
          </Link>
        </div>
        <a href="/" className="navbar-brand mx-5 d-none d-lg-block">
          <h1 className="font-secondary shad text-white mb-n2">
          {user1} <span className="text-primary">&</span> {user2}
          </h1>
        </a>
        <div className="navbar-nav mr-auto py-0">
          <Link
            activeClass="active"
            to="family"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item nav-link"
          >
            Family
          </Link>
          <Link
            activeClass="active"
            to="event"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item nav-link"
          >
            Event
          </Link>
          <Link
            activeClass="active"
            to="rsvp"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item nav-link"
          >
            RSVP
          </Link>
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item nav-link"
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
