import React, { useEffect, useState } from "react";
import './BusRoute.css'; 

const BusRoute = ({visheshamData}) => {
    const [busRoutes, setBusRoutes] = useState([]);

    // useEffect(() => {
    //     const routes = [
    //         { route: "Route 1", details: "Bus no. 25A from Central Station" },
    //         { route: "Route 2", details: "Bus no. 37B from North Square" },
    //         { route: "Route 3", details: "Bus no. 12C from East Market" },
    //         { route: "Route 4", details: "Bus no. 45D from West Park" }
    //     ];
    //     setBusRoutes(routes);
    // }, []);

    return (
        <>
        {
           visheshamData!== null && visheshamData !== undefined && visheshamData.bus_root !== undefined && visheshamData.bus_root.length > 0?
        <div className="busRoutesContainer">
            <div className="busRoutes-container">
                <div className="busRoutes-header">
                    <h2>Bus Routes</h2>
                </div>
                <div className="busRoutes-cards">
                    {
                    visheshamData.bus_root.map((route, index) => (
                        <div className="route-card" key={index}>
                            <i className="fas fa-bus"></i>
                            <h3>{route.bus_routes_bus_no}</h3>
                            <p>{route.bus_routes_description}</p>
                        </div>
                    ))
                    }
                </div>
            </div>
        </div>
        : null
        }
        </>
    );
};

export default BusRoute;
