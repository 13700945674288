import React, { useState, useEffect } from 'react';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import FeedbackForm from './components/FeedbackForm/FeedbackForm';
import FriendsAndFamily from './components/FriendsAndFamily/FriendsAndFamily';
import Event from './components/Event/Event';
import Gallery from './components/Gallery/Gallery';
import Story from './components/Story/Story';
import About from './components/About/About';
import EventTimeLine from './components/EventTimeLine/EventTimeLine';
import Carousel from './components/Carousel/Carousel';
import FoodMenu from './components/FoodMenu/FoodMenu';
import BusRoute from './components/BusRoute/BusRoute';
import BusinessContactInfo from './components/BusinessContactInfo/BusinessContactInfo';
import Games from './components/Games/Games';
import Envelope from './components/Envelope/Envelope';
import { MdSimCardDownload } from "react-icons/md";
import { API_URL } from './const';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function VisheshamScreen() {
    const [visible, setVisible] = useState(false);
    const [visheshamData, setVisheshamData] = useState(null);
    const { id } = useParams();
    const [showEnvelope, setShowEnvelope] = useState(false);
    const [invitationToName, setInvitationToName] = useState("");
    const [invitationMessage, setInvitationMessage] = useState("");


    const getVisheshamData = async () => {
        try {
            const response = await axios.post(`${API_URL}getAllDataByURL`, {
                url: window.location.href.split("?")[0],
            });
            if(window.location.href.includes("?")){
                const params = new URLSearchParams(window.location.search);
                const name = params.get('name')
                const message = params.get('message')
                if(name !== null && name !== undefined) {
                    setShowEnvelope(true);
                    setInvitationToName(name);
                }
                if(message !== null && message !== undefined) {
                    setInvitationMessage(message);
                }
                
            }
            setVisheshamData(response.data.result_Arr);
            console.log(response.data.result_Arr);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getVisheshamData();
        const handleScroll = () => {
            if (window.scrollY > 200) {
            setVisible(true);
            } else {
            setVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [id]);

    return (
        <>
            <NavBar visible={visible} visheshamData={visheshamData}/>
            {
                showEnvelope ? <Envelope invitationToName = {invitationToName} invitationMessage = {invitationMessage}/> : null
            }
            <Carousel visheshamData={visheshamData}/>
            <EventTimeLine visheshamData={visheshamData}/>
            <About visheshamData={visheshamData}/>
            <Story visheshamData={visheshamData}/>
            <Gallery visheshamData={visheshamData}/>
            <Event visheshamData={visheshamData}/>
            <FriendsAndFamily visheshamData={visheshamData}/>
            <FoodMenu visheshamData={visheshamData}/>
            <BusRoute visheshamData={visheshamData}/>
            <BusinessContactInfo visheshamData={visheshamData}/>
            <FeedbackForm visheshamData={visheshamData}/>
            <Footer visheshamData={visheshamData}/>
            <Games visheshamData={visheshamData}/>
            {
                visheshamData && visheshamData.invitation_url?
                <a href={API_URL+visheshamData.invitation_url} download="Invitation" target='_blank'>
                <div style={{position: "fixed", bottom: "25px", right: "25px", zIndex: "1000", backgroundColor: "#121F38", width: "50px", height: "50px",
                    borderRadius: "50%", justifyContent: "center", display: "flex", alignItems: "center", fontSize: "24px", color: "white", cursor: "pointer"}}>
                    <MdSimCardDownload />
                </div>
                </a>
            
                :null
            }
      </>
    );
}

export default VisheshamScreen;