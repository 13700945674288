import React from "react";
// import '../../App.css'
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";


const Footer = ({visheshamData}) => {
  // const phoneNumber = "+1234567890";
  const email = "zeerovalue@gmail.com";
  return (

    <div className="container-fluid bg-dark text-white py-5" id="contact" style={{marginTop: "90px"}}>
      <div className="container text-center py-5">
            <div className="section-title position-relative text-center">
                <h1 className="font-secondary display-3 text-white">Thank You</h1>
                <FaRegHeart />
            </div>
            <div className="d-flex justify-content-center mb-4">
                <a className="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><FaTwitter /></a>
                <a className="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><FaFacebook /></a>
                <a className="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><FaLinkedin /></a>
                <a className="btn btn-lg btn-outline-light btn-lg-square" href="#"><FaInstagramSquare /></a>
            </div>
            <div className="d-flex justify-content-center py-2">
                <p className="text-white" href="#">{email}</p>
                {/* <span className="px-3">|</span>
                <p className="text-white" href="#">{phoneNumber}</p> */}
            </div>
            <p className="m-0">&copy; <a className="text-primary" href="/">Vishesham</a>. Designed by <a className="text-primary" target="_blank" href="https://zeerovalue.com">Zeero Value</a>
            </p>
        </div>
    </div>
  );
};

export default Footer;
