import React, { useEffect, useState } from "react";
import './BusinessContactInfo.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faIdCard, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const BusinessContactInfo = ({visheshamData}) => {
    const [contacts, setContacts] = useState([]);

    // useEffect(() => {
    //     const fetchedContacts = [
    //         { role: "Photographer", name: "John Doe", phone: "+1234567890", email: "john@example.com" },
    //         { role: "Catering", name: "Jane Smith", phone: "+0987654321", email: "jane@example.com" },
    //         { role: "Reception Hall Owner", name: "Bob Johnson", phone: "+1122334455", email: "bob@example.com" }
    //     ];

    //     setContacts(fetchedContacts);
    // }, []);

    return (
        <>
        {
             visheshamData!== null && visheshamData !== undefined && visheshamData.contact !== undefined && visheshamData.contact.length > 0?
        <div className="contactContainer">
            {/* <div className="contact-container"> */}
                <div className="contact-header">
                    <h2>Contact Information</h2>
                </div>
                <div className="contact-cards">
                    {visheshamData.contact.map((contact, index) => (
                        <div className="contact-card" key={index}>
                            <div className="contact-detail">
                                <FontAwesomeIcon icon={faUser} style={{fontSize: "2em", color: "#ff6347", marginBottom: "15px", marginRight: "12px"}}/>
                                <h3>{contact.contact_business_name}</h3>
                            </div>
                            <div className="contact-detail">
                                <FontAwesomeIcon icon={faIdCard} style={{fontSize: "2em", color: "#ff6347", marginBottom: "15px", marginRight: "12px"}}/>
                                <p><strong>{contact.contact_owner_name}</strong></p>
                            </div>
                            <div className="contact-detail">
                                <FontAwesomeIcon icon={faPhone} style={{fontSize: "2em", color: "#ff6347", marginBottom: "15px", marginRight: "12px"}}/>
                                <p>{contact.contact_cell_number}</p>
                            </div>
                            <div className="contact-detail">
                                <FontAwesomeIcon icon={faEnvelope} style={{fontSize: "2em", color: "#ff6347", marginBottom: "15px", marginRight: "12px"}}/>
                                <p>{contact.contact_email_id}</p>
                            </div>
                        </div>
                    ))}
                </div>
            {/* </div> */}
        </div>:null
}
        </>
    );
};

export default BusinessContactInfo;
