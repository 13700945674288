import React, {useState, useEffect} from "react";
import { FaRegHeart } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import { IoMdCall } from "react-icons/io";
import {API_URL} from '../../const';

const Event = ({visheshamData}) => {
    useEffect(() => {
        console.log(visheshamData);
    },[])

  return (

    <div className="container-fluid py-5" id="event">
        <div className="container py-5">
            <div className="section-title position-relative text-center">
                <h6 className="text-uppercase text-primary mb-3" style={{letterSpacing: "3px"}}>Event</h6>
                <h1 className="font-secondary display-4">Our Wedding Event</h1>
                <FaRegHeart />
            </div>
            <div className="row justify-content-center">
                <div className="col-md-6 text-center">
                    <h5 className="font-weight-normal text-muted mb-3 pb-3">Welcome to a venue where your dreams come true, your love story shines, and every moment is crafted into a lasting memory. Celebrate in elegance and make your day unforgettable</h5>
                </div>
            </div>
            <div className="row">
            {
                visheshamData && visheshamData.event_location ? 
                    visheshamData.event_location.map((item, index) =>{
                        return(
                            index%2==0?
                            <div className="col-md-6 border-right border-primary">
                                <div className="text-center centerEventTitle text-md-right mr-md-3 mb-4 mb-md-0">
                                    <img className="img-fluid mb-4" src={API_URL+item.event_location_img_url} alt=""/>
                                    <a target="_blank" href={item.event_location_map_url}>
                                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                                        {
                                            item.event_location_map_url != "" ? 
                                            <span className="mb-3" style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px", marginRight: "10px", cursor: "pointer"}} >
                                                <ImLocation2 />
                                            </span> : null
                                        }
                                        <h2 className="mb-3">{item.event_location_headline}</h2>
                                    </div>
                                    <h6 className="mb-3">{item.event_location_title}</h6>
                                    <p className="mb-2">{item.event_location_full_address}</p>
                                    <p className="mb-0">{item.event_location_start_time} - {item.event_location_end_time}</p>
                                    </a>
                                </div>
                            </div>
                        :
                        <div className="col-md-6">
                            <div className="text-center centerEventTitle text-md-left ml-md-3">
                                <img className="img-fluid mb-4" src={API_URL+item.event_location_img_url} alt=""/>
                                <a target="_blank" href={item.event_location_map_url}>
                                <div style={{display: "flex", justifyContent: "start"}}>
                                    <h2 className="mb-3">{item.event_location_headline}</h2>
                                    {
                                        item.event_location_map_url != "" ? 
                                        <span className="mb-3" style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px", marginLeft: "10px", cursor: "pointer"}} >
                                            <ImLocation2 />
                                        </span> : null
                                    }
                                </div>
                                <h6 className="mb-3">{item.event_location_title}</h6>
                                <p className="mb-2">{item.event_location_full_address}</p>
                                <p className="mb-0">{item.event_location_start_time} - {item.event_location_end_time}</p>
                                </a>
                            </div>
                        </div>
                        )
                    })
                : null
            }
            </div>
        </div>
    </div>
  );
};

export default Event;
