import React, { useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { API_URL } from '../../const';
import axios from 'axios';

const FeedbackForm = ({visheshamData}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    guests: "",
    attending: "",
    message: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    axios.post(API_URL+"submitFeedbackForVisheshamId",
    {
      feedback_form_vishesham_id: visheshamData.id,
      feedback_form_name: formData.name,
      feedback_form_email_id: formData.email,
      feedback_form_no_of_guest: formData.guests,
      feedback_form_events_attending: formData.attending,
      feedback_form_message: formData.message,
    }).then(() => {
      setIsSubmitting(false);
      formData.name= "";
      formData.email= "";
      formData.guests= "";
      formData.attending= "";
      formData.message= "";
    }).catch((e) => {
      console.log(e);
    });
  };

  return (
    <div className="container-fluid py-5" id="rsvp">
      <div className="container py-5">
        <div className="section-title position-relative text-center">
          <h1 className="font-secondary display-4">Join Our Party</h1>
          <FaRegHeart />
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="text-center">
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-sm-6">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="form-control bg-secondary border-0 py-4 px-3"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="form-group col-sm-6">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="form-control bg-secondary border-0 py-4 px-3"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-sm-6">
                    <select
                      name="guests"
                      value={formData.guests}
                      onChange={handleChange}
                      className="form-control bg-secondary border-0"
                      style={{ height: "52px" }}
                      required
                    >
                      <option value="">Number of Guest</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  <div className="form-group col-sm-6">
                    <select
                      name="attending"
                      value={formData.attending}
                      onChange={handleChange}
                      className="form-control bg-secondary border-0"
                      style={{ height: "52px" }}
                      required
                    >
                      <option value="">I'm Attending</option>
                      <option value="All Events">All Events</option>
                      <option value="Wedding Party">Wedding Party</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="form-control bg-secondary border-0 py-2 px-3"
                    rows="5"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div>
                  <button
                    className="btn btn-primary font-weight-bold py-3 px-5"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
                {submissionStatus && <div className="mt-3">{submissionStatus}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackForm;
