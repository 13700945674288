import React from "react";
import { FaRegHeart } from "react-icons/fa";
import { FaMale } from "react-icons/fa";
import { FaFemale } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import {API_URL} from '../../const';

const About = ({visheshamData}) => {

    return (
    <div className="container-fluid py-5" id="about">
        <div className="container py-5">
            <div className="section-title position-relative text-center">
                <h6 className="text-uppercase text-primary mb-3" style={{letterSpacing: "3px"}}>About</h6>
                <h1 className="font-secondary display-4">Groom & Bride</h1>
                <FaRegHeart />
            </div>
            {
            visheshamData != null ? visheshamData.guest_of_honor.map((item,index) =>{
                return(
                    
                    <>
                    {
                    index%2 == 0?
                    <div className="row m-0 mb-4 mb-md-0 pb-2 pb-md-0">
                        <div className="col-md-6 p-0 text-center text-md-right">
                            <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-5">
                                <h3 className="mb-3">{item.gender == "M"?"The Groom": "The Bride"}</h3>
                                <h3 className="font-secondary font-weight-normal text-muted mb-3">
                                {item.gender == "M"? <FaMale />:<FaFemale />}{item.name}</h3>
                                <p>{item.description}</p>
                                <div className="position-relative">
                                    {item.facebook_url != "" ? <a className="btn btn-outline-primary btn-square mr-1" href={item.facebook_url}><FaFacebook /></a> : null}
                                    {item.instagram_url != "" ? <a className="btn btn-outline-primary btn-square" href={item.instagram_url}><FaInstagramSquare /></a> : null}
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0" style={{minHeight: "400px"}}>
                            <img className="position-absolute w-100 h-100" src={API_URL+item.profile_url}  style={{objectFit: "cover"}}/>
                        </div>
                    </div>
                    :
                    <div className="row m-0">
                        <div className="col-md-6 p-0" style={{minHeight: "400px"}}>
                            <img className="position-absolute w-100 h-100" src={API_URL+item.profile_url}  style={{objectFit: "cover"}}/>
                        </div>
                        <div className="col-md-6 p-0 text-center text-md-left">
                            <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-5">
                                <h3 className="mb-3">{item.gender == "M"?"The Groom": "The Bride"}</h3>
                                <h3 className="font-secondary font-weight-normal text-muted mb-3">
                                {item.gender == "M"? <FaMale />:<FaFemale />}{item.name}</h3>
                                <p>{item.description}</p>
                                <div className="position-relative">
                                    {item.facebook_url != "" ? <a className="btn btn-outline-primary btn-square mr-1" href={item.facebook_url}><FaFacebook /></a>: null}
                                    {item.instagram_url != "" ? <a className="btn btn-outline-primary btn-square" href={item.instagram_url}><FaInstagramSquare /></a> : null}
                                </div>
                            </div>
                        </div>
                    </div>
            }
                    </>
                )
            })
          :null  }
        </div>
    </div>
  );
};

export default About;
