import React, { useEffect, useState, useRef } from 'react';
import './EventTimeLine.css';
// import bride from './bride.png';
// import groom from './groom.png';

const EventTimeLine = ({ visheshamData }) => {
    const [time, setTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [progress, setProgress] = useState(0);

    const brideRef = useRef(null);
    const groomRef = useRef(null);
    const timelineLineRef = useRef(null);

    function parseDateString(dateStr) {
        const [datePart, timePart] = dateStr.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds); // month is zero-based
    }

    const defaultEventDate = new Date();
    const defaultCreationDate = new Date();

    const eventDate = visheshamData?.event_start_date ? parseDateString(visheshamData.event_start_date) : defaultEventDate;
    const creationDate = visheshamData?.created_on ? parseDateString(visheshamData.created_on) : defaultCreationDate;

    useEffect(() => {
        function updateCountdown() {
            const now = new Date();
            const timeDifference = eventDate - now;


            if (timeDifference <= 0) {
                setTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
                clearInterval(countdownInterval.current);
                return;
            }

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            setTime({ days, hours, minutes, seconds });

            const totalDuration = eventDate - creationDate;
            const elapsedTime = now - creationDate;
            const newProgress = elapsedTime / totalDuration;
            setProgress(newProgress);

            if (brideRef.current && groomRef.current) {
                const moveDistance = (brideRef.current.parentElement.offsetWidth / 2) * newProgress;
                brideRef.current.style.transform = `translateX(${moveDistance}px)`;
                groomRef.current.style.transform = `translateX(-${moveDistance}px)`;
            }

            if (timelineLineRef.current) {
                const timelineWidth = timelineLineRef.current.offsetWidth;

                const labelPositions = [
                    { id: 'start-date', date: creationDate },
                    { id: 'middle-date', date: eventDate },
                    { id: 'end-date', date: creationDate }
                ];

                labelPositions.forEach(({ id, date }, index) => {
                    const label = document.getElementById(id);
                    if (label) {
                        label.textContent = formatDate(date);
                        const positionPercentage = index / (labelPositions.length - 1);
                        label.style.left = `${positionPercentage * 100}%`;
                        if(index === 0){
                            label.style.left = '5%';
                        } else if(index === 1){
                            label.style.left = '50%';
                        } else if(index === 2){
                            label.style.left = '85%';
                        }
                    }
                });
            }
        }

        function formatDate(date) {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        }

        const countdownInterval = { current: null };
        countdownInterval.current = setInterval(updateCountdown, 1000);
        return () => clearInterval(countdownInterval.current);
    }, [eventDate, creationDate]);

    return (
        <div className="timelineContainer">
            <div className="countdown-container">
                <div className="countdown-item">
                    <div className="countdown-number">{time.days}</div>
                    <div className="countdown-label">Days</div>
                </div>
                <div className="countdown-item">
                    <div className="countdown-number">{time.hours}</div>
                    <div className="countdown-label">Hours</div>
                </div>
                <div className="countdown-item">
                    <div className="countdown-number">{time.minutes}</div>
                    <div className="countdown-label">Minutes</div>
                </div>
                <div className="countdown-item">
                    <div className="countdown-number">{time.seconds}</div>
                    <div className="countdown-label">Seconds</div>
                </div>
            </div>

            <div className="images-container">
                <img ref={brideRef} src={"https://vishesham.zeerovalue.com/vishesham/Images/1/EventTimeline/bride.png"} alt="Bride" className="image bride" />
                <img ref={groomRef} src={"https://vishesham.zeerovalue.com/vishesham/Images/1/EventTimeline/groom.png"} alt="Groom" className="image groom" />
            </div>
            
            <div className="timeline-container">
                <div className="timeline-line" ref={timelineLineRef}></div>
                <div id="start-date" className="timeline-label date-label"></div>
                <div id="middle-date" className="timeline-label date-label"></div>
                <div id="end-date" className="timeline-label date-label"></div>
            </div>
        </div>
    );
};

export default EventTimeLine;
