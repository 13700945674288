import React, {useState,useEffect} from "react";
import '../../App.css';
import {API_URL} from '../../const';
import { FaRegHeart } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";

const FriendsAndFamily = ({visheshamData}) => {
    
    const[isGroom, setIsGroom] = useState(true);

    const changeiSGroom = (value) => {
        setIsGroom(value);
    }

    return (

    <div className="container-fluid py-5" id="family">
        <div className="container pt-5 pb-3">
            <div className="section-title position-relative text-center">
                <h6 className="text-uppercase text-primary mb-3" style={{letterSpacing: "3px"}}>Friends & Family</h6>
                <h1 className="font-secondary display-4">Groomsmen & Bridesmaid</h1>
                <FaRegHeart />
            </div>
            <div className="row">
                <div className="col-12 text-center mb-2">
                    <ul className="list-inline mb-4" id="portfolio-flters">
                        <li onClick = {() => changeiSGroom(true)} className="btn btn-outline-primary font-weight-bold m-1 py-2 px-4" data-filter=".first">Mappillai Veedu</li>
                        <li onClick = {() => changeiSGroom(false)} className="btn btn-outline-primary font-weight-bold m-1 py-2 px-4" data-filter=".second">Ponnu Veedu</li>
                    </ul>
                </div>
            </div>
            <div className="row portfolio-container">
                {
                    visheshamData && visheshamData.members ?
                    (isGroom ?
                        visheshamData.members.filter(prod => prod.is_bride === "0").map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 col-md-6 mb-4 portfolio-item second">
                                    <div className="position-relative mb-2">
                                        <img className="img-fluid w-100" src={API_URL + item.thumbnail} alt="" />
                                        <div className="bg-secondary text-center p-4">
                                            <h4 className="mb-3">{item.member_name}</h4>
                                            <p className="text-uppercase">{item.member_relationship}</p>
                                            <div className="d-inline-block">
                                                {
                                                    item.facebook_url ? <a className="mx-2" href={item.facebook_url} target="_blank"><FaFacebook /></a> : null
                                                }
                                                {
                                                    item.instagram_url ? <a className="mx-2" href={item.instagram_url} target="_blank"><FaInstagramSquare /></a> : null
                                                }
                                                {/* <a className="mx-2" href="#"><FaTwitter /></a>
                                                <a className="mx-2" href="#"><FaLinkedin /></a>
                                                <a className="mx-2" href="#"><FaTwitter /></a>
                                                <a className="mx-2" href="#"><FaFacebook /></a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) :
                        visheshamData.members.filter(prod => prod.is_bride === "1").map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 col-md-6 mb-4 portfolio-item second">
                                    <div className="position-relative mb-2">
                                        <img className="img-fluid w-100" src={API_URL + item.thumbnail} alt="" />
                                        <div className="bg-secondary text-center p-4">
                                            <h4 className="mb-3">{item.member_name}</h4>
                                            <p className="text-uppercase">{item.member_relationship}</p>
                                            <div className="d-inline-block">
                                                {
                                                    item.facebook_url ? <a className="mx-2" href={item.facebook_url}><FaFacebook /></a> : null
                                                }
                                                {
                                                    item.instagram_url ? <a className="mx-2" href={item.instagram_url}><FaInstagramSquare /></a> : null
                                                }
                                                {/* <a className="mx-2" href="#"><FaTwitter /></a>
                                                <a className="mx-2" href="#"><FaLinkedin /></a>
                                                <a className="mx-2" href="#"><FaTwitter /></a>
                                                <a className="mx-2" href="#"><FaFacebook /></a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : null
                }
            </div>

        </div>
    </div>
  );
};

export default FriendsAndFamily;
